export const constants = {
    baseUrl: 'https://app.bio.credit/api/v1/',
    //baseUrl: 'https://qa.bio.credit/api/v1/',
    //baseUrl: 'http://localhost:8000/api',

    // case 'production':
    //             apiHost = "'https://app.bio.credit'";
    //             buildVariant = "/market_prod";
    //             break;
    //         default:
    //             apiHost = "'https://dev.bio.credit'";
    //             buildVariant = "/market_dev";
    //             break;

    endPoints: {
        login: 'analyst/login',
        resetPassword: 'analyst/reset-password',
        changePassword: 'analyst/change-password',
        listProducts: 'analyst/list-products',
        storeProducts: 'analyst/store-products',

        settings: '/settings',
        storeContact: '/store-contact',
        isRegistered: '/is-registered',
        paymentRequest: '/pay',
        validateDocuments: '/validate-documents',
        registerUser: '/register-user',
        user: '/user',
        form: '/form',
        validateFullForm: '/validate-full-form',
        restrictionForm: '/restriction-form',
        saveForm: '/save-field-value',
        policies: '/insurance-policies',
        simulator: '/simulator',
        phoneIsRegistered: '/phone-is-registered',
        registerPhone: '/register-phone',
        validatePhone: '/validate-phone',
        createRequest: '/create-request',
        signDocuments: '/sign-documents',
        signDocument: '/sign-document',
        reSendSignatureOtp: '/re-send-signature-otp',
        startPayment: '/start-payment',
    },

    paymentDays: [
        {
            minDay: 1,
            maxDay: 7,
            day: 4
        },
        {
            minDay: 8,
            maxDay: 14,
            day: 8
        },
        {
            minDay: 15,
            maxDay: 21,
            day: 15
        },
        {
            minDay: 22,
            maxDay: 31,
            day: 22
        }
    ]
};
